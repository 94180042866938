import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import imageData from './Imgcomp';

const Success = () => {
  const { currentUser, isAuthLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavigated, setIsNavigated] = useState(false); 

  useEffect(() => {
    // if (isAuthLoading) return;
    if (isAuthLoading || isNavigated) return;

    const confirmPayment = async () => {
      const searchParams = new URLSearchParams(location.search);
      let itemName;

      // URL에서 `itemName`과 `pg_token` 확인
      const encodedItemName = searchParams.get('itemName');
      if (encodedItemName) {
        try {
          itemName = decodeURIComponent(atob(encodedItemName));
        } catch (e) {
          console.error('Failed to decode itemName from URL:', e);
        }
      }
      
      // URL 파라미터에서 `itemName`이 없다면 `localStorage`에서 대체로 가져오기
      if (!itemName) {
        itemName = localStorage.getItem('pendingItemName');
      }
      
      const pg_token = searchParams.get('pg_token');
      const tid = localStorage.getItem('kakaoPay_tid');

    /*console.log("URL에서 가져온 itemName:", itemName);
      console.log("pg_token:", pg_token); */

     /*  if (!pg_token || !tid ) {
        console.error('결제 정보가 충분하지 않습니다.');
        alert('올바르지 않은 결제 접근입니다.');
        navigate('/');
        return;
      } */
        if (!pg_token || !tid) {
          console.error('결제 정보가 충분하지 않습니다.');
          alert('올바르지 않은 결제 접근입니다.');
          setIsNavigated(true); // 플래그 설정
          navigate('/');
          return;
        }

      try {
        const image = imageData.find(img => img.text === itemName);
        if (!image){
          throw new Error('상품 정보를 찾을 수 없습니다.');
        } 

        await addDoc(collection(db, 'users', currentUser.uid, 'downloads'), {
          itemName: itemName,
          link: image.gdlink || '',
          createdAt: new Date(),
          paymentStatus: 'completed',
          tid: tid
        });

        // 결제 완료 후 `localStorage` 정리
        localStorage.removeItem('kakaoPay_tid');
        localStorage.removeItem('pendingItemName');

    /*     alert('결제가 완료되었습니다. 마이페이지에서 다운로드할 수 있습니다.');
        navigate('/mypage');
      } catch (error) {
        console.error('결제 확인 중 오류:', error);
        alert('결제 확인 중 오류가 발생했습니다.');
        navigate('/mypage');
      } */
        setTimeout(() => {
          alert('결제가 완료되었습니다. 마이페이지에서 다운로드할 수 있습니다.');
          setIsNavigated(true); // 플래그 설정
          navigate('/mypage');
        }, 100);
      } catch (error) {
        console.error('결제 확인 중 오류:', error);
        alert('결제 확인 중 오류가 발생했습니다.');
        setIsNavigated(true); // 플래그 설정
        navigate('/mypage');
      }

    };

    if (currentUser) {
      confirmPayment();
    } else {
      alert('로그인 후 이용 가능합니다.');
      setIsNavigated(true);
      navigate('/login');
    }
  }, [isAuthLoading, currentUser, location.search, navigate, isNavigated]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <div>결제를 확인 중입니다...</div>
    </div>
  );
};

export default Success;


