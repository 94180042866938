import React from 'react'
import styled from 'styled-components'


const Footerwrap = styled.div`
 width: 100%;
 height: 150px;
 /* background-color: rgba(216,216,216,1); */
 /* margin-top: 50px; */
 
 .footerinner{
  max-width: 1200px;
  margin: 0 auto;
   border-top: 4px double rgba(0,0,0,1);

  h2{

    text-align: center;
    line-height: 50px;
    font-weight: 700;
    font-size: 20px;
  }
  p{
    margin-top: 30px;
    font-weight: 700;
    text-align: center;
  }
 }
`
const Footer = () => {
  return (
    <>
      <Footerwrap>
        <div className='footerinner'>
          {/* <h2>문의 : instagram(@gumi_gomi)</h2> */}
          <p style={{fontSize:'12px', fontWeight:'400',color:'rgba(0,0,0,0.8)'}}>대전광역시 서구 탄방동 66-32, 3층 \ 대표 김수환 <br/> 사업자등록번호 169-16-02569 </p>
          <p>COPYRIGHT 2024 GUMIGOMIILLUST, All rights reserved</p>
        </div>
      </Footerwrap>
    </>
  )
}

export default Footer
